import React, { useEffect, Fragment } from "react";
import { Container, Card, CardBody, Button, Row, Col, CardFooter } from "reactstrap";
import SEO from "../components/SEO";
import TitleBar from "../components/Layout/TitleBar";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
import news from "../assets/data/news";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const NewsPage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
      <SEO
        title="News"
        description="Cannabis and Psychedelics Law Group LLP appearing in the media and recent news articles surrounding cannabis and psychedelics law in Ontario and throughout Canada." 
      />

      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="News" />
            <BreadCrumbs page="News" />
            <Row>
              {news.map((item, i) => {
                return (
                  <Col key={i} xs={12} sm={6} style={{marginBottom: "30px"}}>
                    <Card className="h-100 d-flex">
                      {item.imgURL ? 
                      <Row>
                        <Col className="h-100">
                          <img
                            src={item.imgURL}
                            alt={item.title}
                            style={{ width: "100%", height: "300px", objectFit: "cover"}}
                            className="rounded-top"
                          />
                        </Col>
                      </Row> 
                      : null}
                      <CardBody className="p-3">
                        <h6 className="m-0 mr-2">
                          {item.title}
                        </h6>
                      </CardBody>
                      <CardFooter className="px-3 pb-3 mt-auto d-flex justify-content-between">
                        <p className="text-muted m-0 align-self-end" style={{height:"fit-content", fontSize: "14px"}}>{item.date.toDateString()}</p>
                        <OutboundLink href={item.url} target="_blank" rel="noreferrer">
                            <Button
                              block
                              className="m-0"
                              style={{
                                height: "40px",
                                width: "25%",
                                minWidth: "120px",
                              }}
                            >
                              Read Article
                            </Button>
                          </OutboundLink>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default NewsPage;
