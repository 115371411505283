export default [
  {
    title: "Jack Lloyd discusses psilocybin with Bloomberg",
    url: "https://www.bloomberg.com/news/features/2024-01-10/magic-mushroom-boom-in-vancouver-toronto-is-a-challenge-for-police?cmpid=BBD011124_prognosis&utm_medium=email&utm_source=newsletter&utm_term=240111&utm_campaign=prognosis",
    imgURL: "",
    date: new Date(2023, 0, 10),
  },
  {
    title: "Paul Lewin discusses how the Charter Challenge could aid in legalizing psilocybin in Canada on CTV in December 2023",
    url: "https://london.ctvnews.ca/could-a-local-charter-challenge-aid-in-legalizing-magic-mushrooms-in-canada-1.6690127",
    imgURL: "",
    date: new Date(2023, 11, 15),
  },
  {
    title: "Paul Lewin discusses path towards legalization of psilocybin with CTV",
    url: "https://kitchener.ctvnews.ca/we-re-trailblazers-magic-mushroom-advocates-aim-to-pave-path-towards-legalization-1.6648535",
    imgURL: "",
    date: new Date(2023, 10, 16),
  },
  {
    title: "Paul Lewin discusses microdosing with the CBC in April 2023",
    url: "https://www.cbc.ca/radio/microdosing-for-mental-health-despite-little-evidence-1.6825041",
    imgURL: "",
    date: new Date(2023, 3, 29),
  },
  {
    title: "Paul Lewin discusses psilocybin on the Agenda in March 2023",
    url: "https://www.youtube.com/watch?v=OKjlt3cQpW4&t=833s&ab_channel=TheAgenda%7CTVOToday",
    imgURL: "../images/paul-bio.png",
    date: new Date(2023, 2, 30),
  },
  {
    title: "Paul Lewin speaks at the Toronto Psychedelic Community on the Hartle case",
    url: "https://youtu.be/MopEH1nvZkc?t=372",
    imgURL: "https://img.youtube.com/vi/MopEH1nvZkc/0.jpg",
    date: new Date(2023, 1, 24),
  },
  {
    title: "Paul Lewin discusses psilocybin with CTV",
    url: "https://barrie.ctvnews.ca/bradford-dispensary-selling-magic-mushrooms-refuses-to-stay-closed-despite-police-raid-1.6277378",
    imgURL: "",
    date: new Date(2023, 1, 18),
  },
  {
    title: "Paul Lewin discusses psilocybin challenge at Canadian Psychedelic Association webinar",
    url:"https://www.youtube.com/watch?v=A8JksrhTjD4&feature=youtu.be",
    imgURL: "",
    date: new Date(2022, 11, 22),
  },
  {
    title:"Paul Lewin discusses psilocybin and a section 56 exemption for psilocybin for the City of Toronto on 640 Toronto Radio",
    url:"https://pdst.fm/e/chtbl.com/track/745E89/traffic.megaphone.fm/CORU7435096865.mp3?updated=1669658417",
    imgURL: "",
    date: new Date(2022, 10, 28),
  },
  {
    title:"Paul Lewin discusses the psilocybin dispensary in Toronto on NewsTalk 1010",
    url:"https://omny.fm/shows/newstalk1010/police-will-keep-raiding-toronto-magic-mushroom-di",
    imgURL: "",
    date: new Date(2022, 10, 28),
  },
  {
    title:"Paul Lewin discussing the flawed psilocybin laws on CBC",
    url:"https://www.cbc.ca/news/canada/toronto/shroomyz-toronto-dispensary-magic-mushrooms-1.6661739",
    imgURL: "",
    date: new Date(2022, 10, 27),
  },
  {
    title: "Paul Lewin talks with Spencer Hawkswell of Therapsil in October 2022 about the psilocybin constitutional challenge.",
    url: "https://www.youtube.com/watch?v=NEhb-QRDPJs",
    imgURL: "",
    date: new Date(2022, 9, 24),
  },
  {
    title:"Paul Lewin on Metro Morning discussing psilocybin laws in Canada",
    url:"/audio/paul-lewin-metro-morning.mp3", 
    imgURL: "",
    date: new Date(2022, 9, 5),
  },
  {
    title: "Paul Lewin discusses the Hartle psilocybin case on The News Forum in September 2022.",
    url: "https://www.youtube.com/watch?v=HrUh6Q-BQYg",
    imgURL: "",
    date: new Date(2022, 8, 13),
  },
  // WAITING FOR LINK
  // {
  //   title:"Paul Lewin on the CBC discussing psilocybin laws in Canada",
  //   url:"", 
  // },
  {
    title:"Paul Lewin discusses Thomas Hartle’s psilocybin legal challenge",
    url:"https://www.lucid.news/canadian-right-to-try-lawsuit-psilocybin-story/",
    imgURL: "",
    date: new Date(2022, 7, 5),
  },
  {
    title: "Paul Lewin discusses present and future implications for psychedelic law and the current state of psilocybin decriminalization and legalization in Canada in his interview with Frshminds",
    url:
    "https://frshminds.com/paul-lewin-cannabis-psychedelics/",
    imgURL: "",
    date: new Date(2022,  4, 17),
  },
  {
    title: "Shroom boom? Psilocybin mushroom dispensaries sprouting up in Vancouver",
    url:
    "https://bc.ctvnews.ca/shroom-boom-psilocybin-mushroom-dispensaries-sprouting-up-in-vancouver-1.5913494",
    imgURL: "",
    date: new Date(2022, 4, 20),
  },
  {
    title:
      "Former owner of Cannabis Culture becomes general manager of legal store in Brantford",
    url:
      "https://www.cbc.ca/news/canada/hamilton/britney-guerra-harvest-cannabis-1.5788005",
    imgURL: "",
    date: new Date(2020, 10, 3),
  },
  {
    title:
      "Sask. man first in Canada to legally use magic mushrooms in therapy",
    url:
      "https://www.cbc.ca/news/canada/saskatchewan/thomas-hartle-magic-mushroom-psilocybin-therapy-1.5700371",
    imgURL: "",
    date: new Date(2020, 7, 26),
  },
  {
    title:
      "Alberta court says THC limitation of 30mg/ml on THC oil or 10 mg per capsule was a violation of the Charter",
    url:
      "https://stratcann.com/2020/07/04/alberta-court-says-thc-limitation-of-30mg-ml-on-thc-oil-or-10-mg-per-capsule-was-a-violation-of-the-charter/",
    imgURL: "",
    date: new Date(2020, 6, 4),
  },
  {
    title:
      "Regulatory infraction or criminal charge? Licensed companies and individuals are treated very differently when it comes to cannabis laws in Canada",
    url:
      "https://www.thegrowthop.com/cannabis-news/cannabis-legalization/regulatory-infraction-or-criminal-charge-licensed-companies-and-individuals-are-treated-very-differently-when-it-comes-to-cannabis-laws-in-canada?fbclid=IwAR2JikgGnqACiToe0TH9p3JKJSxZUtD9ocdMUZTNwt7pUe065qQXttKB2lA",
    imgURL: "",
    date: new Date(2020, 5, 10),
  },
  {
    title:
      "Vancouver dispensaries ruling supports civic power to license illegal businesses: lawyer",
    url:
      "https://mugglehead.com/vancouver-dispensaries-ruling-supports-civic-power-to-license-illegal-businesses-lawyer/",
    imgURL: "",
    date: new Date(2020, 1, 10),
  },
  {
    title:
      "'Sky didn’t fall:' Police, lawyers still adjusting after pot legalizationn",
    url:
      "https://www.vicnews.com/news/sky-didnt-fall-police-lawyers-still-adjusting-after-pot-legalization/",
    imgURL: "",
    date: new Date(2019, 9, 17),
  },
  {
    title: "Cannabis CEO pleads for Ottawa to shut down booming black market",
    url:
      "https://finance.yahoo.com/news/cannabis-ceo-pleads-for-ottawa-to-shut-down-booming-black-market-094043361.html?guccounter=1&guce_referrer=aHR0cDovL3d3dy5sZXdpbnNhZ2FyYS5jYS8&guce_referrer_sig=AQAAABDgxA4aZ6w3nK8KR01vE7P0QWyP05zLkV4gcMSsm6fWHcqKimzGxDYbrIGT3ZK4FtpRO-ixUm1iaimLeF96PdunwxItO3QM4XnNj9bEe_pkTWlVQknQh-S_hzMsNHZGCIsGreVKsT-HHmc_ERPM_7mgY_fWJjnlEZceh95iHuPL",
    imgURL: "",
    date: new Date(2019, 2, 20),
  },
  {
    title:
      "'Smallest of steps': Cannabis legal expert says pardon changes not enough",
    url:
      "https://www.cbc.ca/news/canada/calgary/cannabis-legal-expert-pardon-changes-bill-c-93-jack-lloyd-1.5042476",
    imgURL: "",
    date: new Date(2019, 2, 4),
  },
  {
    title: "Who you gonna call? These pot professionals have kushy jobs",
    url:
      "https://torontolife.com/city/gonna-call-pot-professionals-kushy-jobs/",
    imgURL: "",
    date: new Date(2019, 1, 25),
  },
  {
    title: "BRAUN: Governments have made a mess of pot legalization",
    url:
      "https://torontosun.com/cannabis/cannabis-culture/braun-governments-have-made-a-mess-of-pot-legalization",
    imgURL: "https://smartcdn.gprod.postmedia.digital/torontosun/wp-content/uploads/2019/01/13x075_4a9c_9-1-e1547407757669.jpg?quality=90&strip=all&w=1128&h=846&type=webp&sig=7VwjDzEdle3-CCxK3NzB4w",
    date: new Date(2019, 0, 13),
  },

  {
    title: "The Cannabis Lawyer: An Interview with Jack Lloyd",
    url: "https://www.leafly.ca/news/canada/cannabis-lawyer-jack-lloyd",
    imgURL: "https://leafly-cms-production.imgix.net/wp-content/uploads/2017/07/20113944/jack-lloyd.jpg?auto=compress,format&w=740&dpr=2",
    date: new Date(2017, 6, 20),
  },
  {
    title: "Paul Lewin speaks on behalf of NORML Canada at 2014 420 in Toronto",
    url: "https://www.youtube.com/watch?v=UFNn5WBJw3k",
    imgURL: "https://img.youtube.com/vi/UFNn5WBJw3k/0.jpg",
    date: new Date(2014, 3, 22),
  },
];
